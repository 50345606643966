body {
    background-color: #2d3234;
    min-width: 1600px;
    font-family: "Open Sans", Arial, serif;
}
.btn-icon-left > svg {
    margin-right: 1rem;
}

.btn-danger {
    background: #ff0000;
    border-color: #ff0000;
}

.btn-danger:hover {
    background: #fff;
    border-color: #ff0000;
    color: #ff0000;
}

.spinner {
    animation: fa-spin 2s linear infinite;
}

.btn-spinner {
    display: flex;
    align-items: center;
}

.btn {
    border-radius: 22px;
    padding: 0.25rem 2rem;
}

.container-xxl {
    max-width: 1600px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
